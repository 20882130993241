import React from "react"
import Layout from "../components/layout"
import ContactSection from "../components/ContactSection"
import MapEmbed from "../components/MapEmbed"
import Seo from "../components/seo"

const Appointment = () => {
  const onSubmit = e => {
    e.preventDefault()
  }

  return (
    <Layout>
      <Seo title="Book Appointment | ANL | Mechanic Shop in New Lynn Auckland" />
      <ContactSection onSubmit={onSubmit} />
      <MapEmbed />
    </Layout>
  )
}

export default Appointment
